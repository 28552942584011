export const Events = {
    INPUT: 'input',
    READY: 'ready',
    LOADING: 'loading',
    SEARCH: 'search',
    CHANGE: 'change',
    CLICK: 'click',
    LOAD: 'load',
    PAGE_LOADED: 'page loaded',
    SUBMIT: 'submit',
    MORE: 'MORE',
    BUY: 'BUY',
    ADD: '+',
    REMOVE: '-',
    LOADING_START: 'loading-start',
    LOADING_STOP: 'loading-stop',
}
