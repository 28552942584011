export default class Helpers {
  static async(makeGenerator) {
    return function (...args) {
      var generator = makeGenerator.apply(this, args);

      function handle(result) {
        // result => { done: [Boolean], value: [Object] }
        if (result.done) return Promise.resolve(result.value);

        return Promise.resolve(result.value).then(
          function (res) {
            return handle(generator.next(res));
          },
          function (err) {
            return handle(generator.throw(err));
          }
        );
      }

      try {
        return handle(generator.next());
      } catch (ex) {
        return Promise.reject(ex);
      }
    };
  }

  static dashToCamelCase(str) {
    return str.replace(/(-\w)/g, (value) => value[1].toUpperCase());
  }

  static compose(prev, curr) {
    return (...args) => prev(curr(...args));
  }

  static pipe(...fns) {
    return fns.reduceRight(Helpers.compose);
  }

  static mapObjectToParams(obj) {
    if (!obj) return "";
    return Object.keys(obj).reduce((acc, key) => {
      const el = key + "=" + obj[key];
      return acc + (acc ? "&" : "?") + el;
    }, "");
  }

  static createEl(tagName, target, params) {
    var el = document.createElement(tagName);
    if (params) {
      Object.keys(params).forEach(function (key) {
        if (key in el) {
          el[key] = params[key];
        } else {
          el.setAttribute(key, params[key]);
        }
      })
    }
    target instanceof HTMLElement
      ? target.appendChild(el)
      : document.querySelector(target).appendChild(el);

    return el;
  }

  static getHtmlTmpl(textTemplate) {
    return new DOMParser()
      .parseFromString(textTemplate, "text/html")
      .querySelector("template");
  }

  static arrayToEntities(arr) {
    return arr.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
  }

  static addOrIncreaseParam(data, newItem, param) {
    const found = data.find((item) => item.id === newItem.id);
    return [
      ...(found
        ? data.map((item) =>
            item === found ? { ...found, [param]: found[param] + 1 } : item
          )
        : [...data, { ...newItem, [param]: 1 }]),
    ];
  }

  static removeOrDecreaseParam(data, itemToDelete, param) {
    const found = data.find((item) => item.id === itemToDelete.id);
    return found[param] === 1
      ? data.filter((item) => item !== found)
      : data.map((item) =>
          item === found ? { ...found, [param]: found[param] - 1 } : item
        );
  }

  static remove(data, itemToDelete) {
    const found = data.find((item) => item.id === itemToDelete.id);
    return data.filter((item) => item !== found);
  }
}

Helpers.delay = (() => {
  let timer;
  return (cb, time = 500) => {
    clearTimeout(timer);
    timer = setTimeout(() => cb(), time);
  };
})();
