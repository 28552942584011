export class BaseComponent extends HTMLElement {
    constructor() {
        super();
    }

    renderContent(id) {
        const content = this.contentTpl.content.cloneNode(true);
        return Array.from(content.childNodes)
            .filter((el) => el.nodeType === 1)
            .map((el) => {
                el.setAttribute('data-id', id);
                return el.outerHTML;
            })
            .join('');
    }

}
