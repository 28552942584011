import { Helpers } from ".";
import { Events } from "./events";

function connectTpl(el, tplStr) {
    const view = Helpers.getHtmlTmpl(tplStr);
    el.appendChild(view.content.cloneNode(true));
    // w templatkach tworzymy znacznik z klasą "body"
    el.body = el.querySelector('.body');
    // jako kontent do komponentu możemy przekazać znacznik "template"
    el.contentTpl = el.querySelector('template');
    el.dispatchEvent(new CustomEvent(Events.READY));
}

function overloadConnectedCallback(tplStr, target, found) {
    const originalValue = found.descriptor.value
    found.descriptor = {
        ...found.descriptor, value: function (...args) {
            connectTpl(this, tplStr);
            return originalValue.apply(this, args);
        }
    };
    return target;
}

function addConnectedCallback(tplStr, target) {
    const descriptor = {
        writable: true,
        configurable: true,
        enumerable: false,
        value: function () {
            connectTpl(this, tplStr);
        }
    }
    target.elements.push({
        kind: 'method',
        key: 'connectedCallback',
        placement: 'prototype',
        descriptor
    });
    return target;
}

function checkConnectedCallback(tplStr, target) {
    const found = target.elements.find((el) => el.key === 'connectedCallback');
    found ? overloadConnectedCallback(tplStr, target, found) : addConnectedCallback(tplStr, target);
}

export function Component({ tpl }) {
    return checkConnectedCallback.bind(this, tpl);
}
